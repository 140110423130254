<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	PopoverContent,
	type PopoverContentEmits,
	type PopoverContentProps,
	PopoverPortal,
	useForwardPropsEmits,
} from 'radix-vue';
import { cn } from '@laam/lib/utils';

defineOptions({
	inheritAttrs: false,
});

const props = withDefaults(
	// eslint-disable-next-line vue/require-default-prop
	defineProps<PopoverContentProps & { class?: HTMLAttributes['class'] }>(),
	{
		align: 'center',
		sideOffset: 4,
	},
);
const emits = defineEmits<PopoverContentEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<PopoverPortal>
		<!-- <PopoverOverlay /> -->
		<PopoverContent
			v-bind="{ ...forwarded, ...$attrs }"
			:class="
				cn(
					'z-50 rounded-medium border border-gray-200 bg-white p-none text-neutral-950 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-xxs data-[side=left]:slide-in-from-right-xxs data-[side=right]:slide-in-from-left-xxs data-[side=top]:slide-in-from-bottom-md',
					props.class,
				)
			"
		>
			<slot />
		</PopoverContent>
	</PopoverPortal>
</template>
